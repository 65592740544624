import './components/Styles/App.scss';
import { LotNumberSearch } from "./components/Lot Number Search/LotNumberSearch";
import { useState } from 'react';
import { DocumentsList } from './components/Documents/DocumentsList';
import { BrowserRouter as Router } from "react-router-dom";
import { Logo } from './components/Logo/Logo';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-LFRZKWMP11');
ReactGA.send({hitType: "pageview", page: window.location.pathname});

function App() {
  const [documents, setDocuments] = useState([]);

  const updateDocuments = ( docs ) => {
    const lc = docs.filter( document => document.fileName !== null);
    setDocuments(lc);
  }

  return (
    <Router>
      <div className="App">
        <div className="content-container">
          <Logo />
          <LotNumberSearch 
            setDocuments={ updateDocuments }
          />
          <DocumentsList 
            documents={ documents } 
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
