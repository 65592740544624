import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ProductsDropdown } from '../Products Dropdown/ProductsDropdown';
import { useLocation } from 'react-router-dom';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import { useStateWithSessionStorageArray } from '../../hooks/customHooks';
import { Error } from '../Error/Error';

export function LotNumberSearch({setDocuments}) {

    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [products, setProducts] = useStateWithSessionStorageArray('afd-portal-products');
    const [selectedProduct, setSelectedProduct] = useState({id: 0});
    const [lotNumber, setLotNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const location = useLocation();
    const path = location.pathname.substring(1) || '';
    const itemIndex = path === '' ? 1 : parseInt(path);
    const validateSearchInput = new RegExp('([0-9]+\-?[a-z]?)')

    const url = 'https://documentportal.appliedfooddiagnostics.com';

    const updateLotNumber = (number) => {
        if(number.length < 13){
            setLotNumber(number)
        }
    }

    const updateSelectedProduct = ( product ) => setSelectedProduct(product);

    const initializeSelectedProduct = ( productsArray = [] ) => {
        const product = productsArray.length === 0 ? products.find(product => product.id === itemIndex) : productsArray.find(product => product.id === itemIndex);
        product ? updateSelectedProduct(product) : updateSelectedProduct(products[0]);
    } 

    useEffect(() => {
        async function initializeProducts(){
            let localProducts;
            if(products.length === 0){
                await axios.get(`${url}/api/product/allProducts`)
                    .then((res) => {
                            setProducts(res.data);
                            localProducts = res.data;
                    })
                    .catch((e) => {
                        setErrorMessage('Error loading products. Please refresh and try again.');
                        setShowErrorMessage(true);
                        console.log('error getting products', e)
                    })
            }
            products.length === 0 ? initializeSelectedProduct(localProducts) : initializeSelectedProduct();
            setIsLoading(false);
        }
        initializeProducts();
    }, []);

    const handleSearch = () => {
        if( lotNumber !== '' && validateSearchInput.test(lotNumber)){
            setIsSearching(true);
            axios.post(`${url}/api/product/searchLot`, {
                productId: selectedProduct.id,
                lotNumber: parseInt(lotNumber)
            })
                .then((res) => {
                    if(res.data === ''){
                        alert('fired')
                        setErrorMessage('No matches for lot number. Please type it again and retry.');
                        setShowErrorMessage(true);
                    } else {
                        setShowErrorMessage(false);
                        setErrorMessage('');
                        const lc = [];
                        const coa = res.data.coa;
                        const sds = res.data.sds;
                        const kitInsert = res.data.kitInsert;
                        const spanishKitInsert = res.data.spanishKitInsert;
                        const spanishSDS = res.data.spanishSds;
                        coa.type = 'COA';
                        sds.type = 'SDS';
                        kitInsert.type = 'Kit Insert';
                        spanishKitInsert.type = 'Kit Insert (Spanish)';
                        lc.push(coa, sds, kitInsert, spanishKitInsert, spanishSDS);
                        setDocuments(lc);
                        setIsSearching(false);
                    }                    
                })

                .catch(e => {
                    console.log('error finding documents', e);
                    setDocuments([]);
                    if(e.response && e.response.status === 404){
                        setErrorMessage('There were no documents found for the lot number and product combination you entered.');
                        setShowErrorMessage(true);
                    } else {
                        setErrorMessage('Error Fetching Documents. Please check your internet connection. If the issue persists, please refresh and try again.');
                        setShowErrorMessage(true);
                    }
                })
            setIsSearching(false);
        } else {
            alert('Please enter a valid lot number');
        }
    }

    if(isLoading){
        return(
            <div className="loading-container">
                <HashLoader color={'#6ac49e'} loading={isLoading} size={50} css={css}/>
                <p>Fetching Tests</p>
            </div>
        )    
    } else if ( products === undefined || products.length === 0){
        return(
            <div>
                <Error 
                    showError={true} 
                    message={'Error loading products. Please check internet connection and try again.'} 
                    style={{margin: '20px auto'}}
                />
            </div>
        )
    } else {
        return(
            <>
                {
                    path ? 
                    <p className="subtitle"> Please enter your lot number below to find relevant documentation.</p>
                    :
                    <p className="subtitle">Please select your product and enter your lot number below to find relevant documentation.</p>
                }
                <div className="lot-number-search-container">
                    <ProductsDropdown 
                        products={products} 
                        selectedProduct={selectedProduct} 
                        updateSelectedProduct={updateSelectedProduct}                           
                    />
                    <input 
                        placeholder="Lot Number. Ex: 51398" 
                        type="text" 
                        value={lotNumber} 
                        onWheel={(e) => e.target.blur()}
                        onChange={e => updateLotNumber(e.target.value)}
                        onKeyPressCapture={(e) => {
                            if(e.code === 'Enter'){
                                handleSearch()
                            }
                        }}
                        min="0"
                        size="13"
                        maxLength="13"
                    />
                    <button 
                        onClick={handleSearch}>
                        { isSearching ? <HashLoader color={'white'} loading={isSearching} size={30} css={css} /> : 'Search' }
                    </button>
                </div>
                <Error showError={showErrorMessage} setShowError={setShowErrorMessage} message={errorMessage} />
            </>
        )
    }
}
