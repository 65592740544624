import React from 'react'

export function ProductsDropdown({products, selectedProduct, updateSelectedProduct }) {
    const allProductTypes = products.map(product => product.product_type.name);
    const productTypes = [... new Set(allProductTypes)]

    return (
        <select 
            value={selectedProduct.id}
            onChange={(e) => updateSelectedProduct(products.find (product => product.id == e.target.value))}
        >
            { productTypes.map((type, i) => {
                const options = products.filter(product => product.product_type.name === type);
                if(i === 0){
                    return(
                        <optgroup label={type} key={i}>
                            {
                                options.map( (option, i) => <option value={option.id} key={i}>{option.name}</option>)
                            }
                        </optgroup>
                    )
                } else {
                    return(
                        <optgroup label={type} key={i}>
                            {
                                options.map( (option, i) => <option value={option.id} key={i}>{option.name}</option>)
                            }
                        </optgroup>
                    )
                }
            })}
    </select>
    )
}
