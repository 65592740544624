import { useState, useEffect } from "react";

export const useStateWithSessionStorageArray = sessionStorageKey => {
    const [value, setValue] = useState(sessionStorage.getItem(sessionStorageKey) || JSON.stringify([]));

    useEffect(() => {
        sessionStorage.setItem(sessionStorageKey, value)
    }, [value])

    const updateValue = (value) => {
        const string = JSON.stringify(value);
        setValue(string);
    }

    return [JSON.parse(value), updateValue]
}