import React, { useState } from 'react';
import { FcOpenedFolder, FcFolder } from 'react-icons/fc';

export function DocumentsList({documents}) {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <div className="document-list">
            {
                isOpen ?
                    <FcOpenedFolder className="folder-icon" size={35} onClick={() => setIsOpen(!isOpen)} />
                    :
                    <FcFolder className="folder-icon" size={35} onClick={() => setIsOpen(!isOpen)}/>
            }
            <p style={{marginLeft: "10px"}}>{documents.length - 1 > 0 ? documents.length - 1 : 0} documents</p>
            <div className="links-container">
                {
                    isOpen && documents.length > 0 ? 
                        documents.map( (document, i, arr) => {
                            return(
                                i === arr.length - 1 ?
                                    <React.Fragment key={i}>
                                    </React.Fragment>
                                    :
                                    <a href={document.pdf} target="_blank" rel="noreferrer" key={i}>
                                        <span>{ document.type || 'No type listed' }:</span> { document.fileName }
                                    </a>
                            )
                        })
                        :
                        <>
                        </>
                }
            </div>
        </div>
    )
}
