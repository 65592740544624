import React from 'react';
import { FaRegFrown } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';

export function Error({showError, setShowError, message, style}) {

    return (
        <>
            { showError ? 
                <div className="error-container" style={style}>
                    { setShowError ? 
                        <GrFormClose className="error-container-close" color='white' size={15} onClick={() => setShowError(false)}/> 
                        : 
                        <>
                        </>
                    }
                    <FaRegFrown color='white' size={25}/>
                    <p className="error-message">{message}</p>
                </div>
                :
                <>
                </>
            }
        </>
    )
}
